import React, { useState, useContext } from "react";
import { useTiktokBusinessAccountList, useTiktokBusinessAccountService } from "@/hooks/tiktokBusinessAccount.hooks";
import { _Button } from "@/components/button";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { ModeToggle } from "@/components/mode-toggle";
import { LoadingFallback } from "@/components/loading-fallback";
import {
  CircleXIcon,
  ShoppingBagIcon,
  ChevronDownIcon,
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Loader2,
  MegaphoneIcon,
  PlusIcon,
  Ban,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { MergoioLogo } from "@/components/layout/MergoioLogo";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { OrderCount } from "@/components/order-count";
import { useToast } from "@/hooks/toast.hooks";

export const BusinessAccounts = () => {
  const { businessAccounts, isLoading, error } = useTiktokBusinessAccountList();
  const { getAuthorizeUrl } = useTiktokBusinessAccountService();
  const [authorizing, setAuthorizing] = useState(false);
  const { toast } = useToast();

  const handleAuthorize = async () => {
    setAuthorizing(true);
    const response = await getAuthorizeUrl();
    if (!response.success) {
      setAuthorizing(false);
      toast({
        variant: "destructive",
        title: "Error",
        description: response.errorMessage || "Failed to get authorization URL",
      });
      return;
    }
    window.location.href = response.content.url;
  };

  return (
    <div className="w-full flex flex-col items-center gap-4 justify-center min-h-screen bg-background p-4">
      <Card className="w-full max-w-7xl min-h-[50%]">
        <CardHeader className="space-y-1">
          <div className="flex justify-between gap-10 items-center">
            <div>
              <CardTitle className="text-2xl font-bold">
                Connected TikTok Business Accounts
              </CardTitle>
              <CardDescription>Manage and authorize accounts</CardDescription>
            </div>
            <div className="flex space-x-2">
              <_Button
                variant="secondary"
                className="w-[250px] flex gap-4 justify-between items-center"
                onClick={handleAuthorize}
                loading={authorizing}
              >
                Add Business Account
                <PlusIcon className="h-4 w-4" />
              </_Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="bg-destructive/10 rounded-lg px-3 py-2 text-sm flex items-center text-destructive">
                <CircleXIcon size={16} className="mr-2" />
                {error.message}
              </div>
            ) : (
              <div className="space-y-4">
                {businessAccounts?.length === 0 ? (
                  <div className="pt-24 flex flex-col items-center justify-center text-muted-foreground">
                    <Ban className="h-12 w-12 mb-4" />
                    <p className="text-lg opacity-75">No business accounts linked</p>
                    <p className="text-sm opacity-50">
                      Connect your TikTok Business Account to start managing your
                      ads
                    </p>
                  </div>
                ) : (
                  businessAccounts?.map((account, index) => (
                    <div key={account.id} className="w-full space-y-2 border rounded-lg">
                      <div className="bg-muted/40 flex gap-2 items-center p-4 border-b rounded-lg">
                        <ShoppingBagIcon className="mr-4 h-6 w-6 text-muted-foreground" />
                        <div className="lg:w-1/3 w-full break-words">
                          <p className="font-medium">
                            Business Account {index + 1}
                          </p>
                        </div>
                        <div className="lg:w-2/3 w-full flex items-center justify-end gap-2">
                          <Badge
                            className={cn(
                              account.setupOperationsCompleted
                                ? "bg-green-500/10 text-green-500"
                                : "bg-orange-500/10 text-orange-500",
                              "flex items-center gap-2"
                            )}
                          >
                            {account.setupOperationsCompleted ? (
                              <>
                                <CheckCircle className="h-4 w-4" />
                                Setup Complete
                              </>
                            ) : (
                              <>
                                <AlertCircle className="h-4 w-4" />
                                Setup Incomplete
                              </>
                            )}
                          </Badge>
                        </div>
                      </div>

                      {/* Advertisers List */}
                      <div>
                        {account.advertisers.map((advertiser) => (
                          <div
                            key={advertiser.advertiserId}
                            className="bg-background hover:bg-muted/40 cursor-pointer flex gap-2 items-center p-3 border rounded-lg mb-2 mx-4 ml-10"
                          >
                            <MegaphoneIcon className="h-5 w-5 text-muted-foreground" />
                            <div className="flex-1">
                              <p className="text-sm">
                                Advertiser ID: {advertiser.advertiserId}
                              </p>
                            </div>
                            <ChevronRight className="h-4 w-4 text-muted-foreground" />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </LoadingFallback>
        </CardContent>
      </Card>
    </div>
  );
};
