import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { _Button } from "@/components/button";
import { LoadingFallback } from "@/components/loading-fallback";
import { useTiktokBusinessAccountService } from "@/hooks/tiktokBusinessAccount.hooks";
import { CircleXIcon, ChevronRightIcon, CheckIcon, CheckCircleIcon } from "lucide-react";
import { useToast } from "@/hooks/toast.hooks";

export const TiktokAdsRedirect = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { authorize } = useTiktokBusinessAccountService();
  const navigate = useNavigate();
  const { toast } = useToast();

  const authCode = searchParams.get("auth_code");

  const handleAuthorization = async () => {
    if (!authCode) {
      setErrorMsg("No authorization code found");
      return;
    }

    setLoading(true);
    setErrorMsg(null);

    const response = await authorize({
      authCode: authCode,
    });

    setLoading(false);

    if (response.success) {
      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "Your TikTok business account has been successfully authorized.",
        duration: 2000,
      });
      navigate("/tiktok/businessAccounts");
    } else {
      setErrorMsg(
        response.errorMessage || "Failed to authorize TikTok account"
      );
    }
  };

  return (
    <div className="w-full min-h-screen bg-background flex items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8 bg-card px-8 rounded-xl shadow-lg">
        <div className="text-center">
          <div className="flex items-center justify-center mb-4">
            <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center">
              <CheckIcon className="h-6 w-6 text-green-600" />
            </div>
          </div>
          <h2 className="text-3xl font-bold text-foreground text-green-600">
            TikTok Authorized
          </h2>
          <p className="mt-2 text-lg text-muted-foreground">
            You've successfully connected your TikTok account
          </p>
        </div>

        {errorMsg && (
          <div className="bg-destructive/10 rounded-xl px-4 py-3 flex gap-2 items-center text-destructive border border-destructive/20">
            <CircleXIcon size={16} />
            {errorMsg}
          </div>
        )}

        <div className="mt-8">
          <_Button
            onClick={handleAuthorization}
            loading={loading}
            className="h-14 shadow-lg shadow-green-500/20 bg-green-600 hover:bg-green-700 group relative w-full flex justify-between items-center py-3 px-4 border border-transparent text-lg font-medium rounded-xl text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary/50 transition duration-150 ease-in-out"
          >
            Link account to Mergoio
            <ChevronRightIcon
              className="ml-2 h-6 w-6 opacity-75 group-hover:opacity-100 transition-opacity"
              aria-hidden="true"
            />
          </_Button>
        </div>
      </div>
    </div>
  );
};

export default TiktokAdsRedirect;
