import React, { useContext, useState } from "react";
import {
  Menu,
  AlertTriangle,
  FilterIcon,
  PlusIcon,
  UploadIcon,
  PlusCircleIcon,
  PencilIcon,
  X,
  CheckCircleIcon,
  XCircleIcon,
  ArrowRightFromLineIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { LoadingFallback } from "@/components/loading-fallback";
import { cn } from "@/lib/utils";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useOrganisationList, useOrganisationService } from "@/hooks/organisation.hooks";
import moment from "moment";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { OrganisationPlan } from "@/types/Organisation";
import { useToast } from "@/hooks/toast.hooks";
import { AdminStateContext } from "@/contexts/AdminStateProvider";
import { useUserService } from "@/hooks/user.hooks";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { useNavigate } from "react-router";
import { _Button } from "@/components/button";
import { OrganisationExcerptView } from "./organisation-excerpt-view";
import { MyAdminAccountDropdown } from "@/components/my-admin-account-dropdown";

const planConfig: Record<string, { name: string; color: string }> = {
  FREE: {
    name: "Free",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
  TRIAL: {
    name: "Trial",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  NONE: {
    name: "None",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
  BEGINNER: {
    name: "Beginner",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  EXPERIENCED: {
    name: "Experienced",
    color: "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100",
  },
  BIG: {
    name: "Big",
    color:
      "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  MASTER: {
    name: "Master",
    color:
      "bg-indigo-100 text-indigo-800 dark:bg-indigo-800 dark:text-indigo-100",
  },
  CUSTOM: {
    name: "Custom",
    color: "bg-pink-100 text-pink-800 dark:bg-pink-800 dark:text-pink-100",
  },
};

const planStatusConfig: Record<string, { name: string; color: string }> = {
  ACTIVE: {
    name: "Active",
    color: "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100",
  },
  INACTIVE: {
    name: "Inactive",
    color: "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
  },
  PAYMENT_FAILED: {
    name: "Payment Failed",
    color: "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100",
  },
  TRIAL: {
    name: "Trial",
    color:
      "bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-100",
  },
  CANCELLED: {
    name: "Cancelled",
    color: "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-100",
  },
};

export const Organisations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null);
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
  const [selectedPlanStatuses, setSelectedPlanStatuses] = useState<string[]>(
    []
  );
  const {toast} = useToast()
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const pageSize = 20;
  const { loginAsOrganisation } = useOrganisationService();
  const [loggingIn, setLoggingIn] = useState(false);
  const {setIsUserViewing} = useContext(AdminStateContext)
  const {signIn: loginUser} = useContext(UserStateContext)
  const navigate = useNavigate()

  const { organisations, isLoading, error, isFetched } = useOrganisationList(
    currentPage,
    pageSize,
    selectedPlans.length > 0 ? selectedPlans : undefined,
    selectedPlanStatuses.length > 0 ? selectedPlanStatuses : undefined
  );

  const handlePlanChange = (plan: string) => {
    setSelectedPlans((prev) => {
      if (prev.includes(plan)) {
        return prev.filter((p) => p !== plan);
      }
      return [...prev, plan];
    });
  };

  const handlePlanStatusChange = (status: string) => {
    setSelectedPlanStatuses((prev) => {
      if (prev.includes(status)) {
        return prev.filter((s) => s !== status);
      }
      return [...prev, status];
    });
  };

  const handleLoginAsOrganisation = async () => {
    setLoggingIn(true);
    try {
      const response = await loginAsOrganisation(selectedOrganisation.id);
      if (!response.success) {
        toast({
          icon: <XCircleIcon size={12} className={"text-red-500"} />,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            response.errorMessage || "Failed to resume subscription.",
        });
        return;
      }

      toast({
        icon: <CheckCircleIcon size={12} className={"text-green-500"} />,
        variant: "default",
        title: "Success",
        description: "You are now logged in as the organisation.",
        duration: 2000,
      });
      loginUser(response.content.token, response.content.userRole);
      setIsUserViewing(true);
      navigate("/");
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An unexpected error occurred while resuming subscription.",
        variant: "destructive",
      });
    } finally {
      setLoggingIn(false);
    }
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <h2 className="text-xl font-semibold text-foreground">
              Organisations
              {isFetched &&
                (organisations && organisations.totalCount
                  ? ` (${organisations.totalCount})`
                  : " (0)")}
            </h2>
            <Dialog open={isFilterOpen} onOpenChange={setIsFilterOpen}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="flex items-center gap-2"
                >
                  <FilterIcon size={14} />
                  <span>Filters</span>
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>Filter Organisations</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="space-y-4">
                    <Label className="text-base">Plan</Label>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(planConfig).map(([plan, config]) => (
                        <div key={plan} className="flex items-center space-x-2">
                          <Checkbox
                            id={plan}
                            checked={selectedPlans.includes(plan)}
                            onCheckedChange={() => handlePlanChange(plan)}
                          />
                          <Label htmlFor={plan}>{config.name}</Label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-4">
                    <Label className="text-base">Plan Status</Label>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(planStatusConfig).map(
                        ([status, config]) => (
                          <div
                            key={status}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox
                              id={status}
                              checked={selectedPlanStatuses.includes(status)}
                              onCheckedChange={() =>
                                handlePlanStatusChange(status)
                              }
                            />
                            <Label htmlFor={status}>{config.name}</Label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button onClick={() => setIsFilterOpen(false)}>
                    Apply Filters
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <MyAdminAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500 font-semibold">
                Error: {error.message}
              </div>
            ) : (
              <>
                <div className="bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="font-semibold text-foreground/70">
                          Company Name
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Subscription ID
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Plan
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Plan Status
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Latest Order Count
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Order Count Updated
                        </TableHead>
                        <TableHead className="font-semibold text-foreground/70">
                          Created/Updated At
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {organisations &&
                      organisations.items &&
                      organisations.items.length > 0 ? (
                        organisations.items.map((org) => (
                          <TableRow
                            key={org.id}
                            className="hover:bg-muted/50 transition-colors cursor-pointer"
                            onClick={() => setSelectedOrganisation(org)}
                          >
                            <TableCell>
                              <b>{org.name || "-"}</b>
                            </TableCell>
                            <TableCell>
                              {org.planSubscriptionId || "-"}
                            </TableCell>
                            <TableCell>
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  planConfig[org.plan as OrganisationPlan].color
                                }`}
                              >
                                {planConfig[org.plan as OrganisationPlan].name}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  planStatusConfig[org.planStatus].color
                                }`}
                              >
                                {planStatusConfig[org.planStatus].name}
                              </span>
                            </TableCell>
                            <TableCell>{org.latestOrderCount}</TableCell>
                            <TableCell>
                              {moment(org.latestOrderCountUpdatedAt).format(
                                "MMM D, YYYY h:mm A"
                              )}
                            </TableCell>
                            <TableCell>
                              <div className="flex flex-col">
                                <span className="text-sm text-foreground/40">
                                  <PlusCircleIcon
                                    size={14}
                                    className="mr-1.5 inline-block"
                                  />
                                  {moment(org.createdAt).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </span>
                                <span className="text-sm text-foreground/70">
                                  <PencilIcon
                                    size={14}
                                    className="mr-1.5 inline-block"
                                  />
                                  {moment(org.updatedAt).format(
                                    "MMM D, YYYY h:mm A"
                                  )}
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={6}
                            className="text-center py-8 text-foreground/60"
                          >
                            No organisations found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {organisations && organisations.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {Array.from(
                        { length: organisations.totalPages },
                        (_, i) => i + 1
                      ).map((page) => (
                        <PaginationItem key={page}>
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            isActive={page === organisations.currentPage}
                            className={cn(
                              "rounded-full cursor-pointer",
                              page === organisations.currentPage
                                ? "bg-blue-500/50"
                                : "bg-background hover:bg-muted/50",
                              "border transition-colors"
                            )}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}

                <OrganisationExcerptView
                  open={!!selectedOrganisation}
                  organisation={selectedOrganisation}
                  setOpen={setSelectedOrganisation}
                />
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
