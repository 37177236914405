import React, { useContext, useState, useEffect } from "react";
import {
  Menu,
  User,
  LogOut,
  ChevronDown,
  AlertTriangle,
  FilterIcon,
  ImageIcon,
  TrendingUp,
  DollarSign,
  Package,
  CreditCard,
  Percent,
  Truck,
  Receipt,
} from "lucide-react";
import { UserStateContext } from "@/contexts/UserStateProvider";
import { ModeToggle } from "@/components/mode-toggle";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { LoadingFallback } from "@/components/loading-fallback";
import { cn, getDateRange } from "@/lib/utils";
import { MyAccountDropdown } from "@/components/my-account-dropdown";
import { useNavigate } from "react-router";
import { CurrencySelect } from "@/components/currency-select";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { useProductListBySales } from "@/hooks/product.hook";
import { ChartTileOverview } from "@/components/chart-tile";
import { ChartSettingsPopover } from "../../../../components/chart-settings-popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useLiteProductStatistics } from "@/hooks/tiktokOrder.hooks";
import { LiteStatisticPeriod } from "@/types/LiteStatisticPeriod";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LiteProductStatistic } from "@/types/LiteProductStatistic";

const fieldMappings: any = {
  units: {
    header: "Units Sold",
    color: "#9333EA",
    icon: Truck,
    order: 0.5,
    isNotCurrency: true,
  },
  totalGrossSales: {
    header: "Gross Sales",
    color: "#10B981",
    icon: DollarSign,
    order: 1,
  },
  totalMerchandiseSales: {
    header: "Merchandise Sales",
    color: "#3B82F6",
    icon: DollarSign,
    order: 2,
  },
  netProfits: { header: "Net Profits", color: "#14B8A6", icon: TrendingUp, order: 3 },
  costOfGoods: { header: "Cost of Goods", color: "#8B5CF6", icon: Package, order: 4 },
  totalTax: { header: "Total Tax", color: "#EF4444", icon: Receipt, order: 5 },
  totalEstimatedAffiliateCommission: {
    header: "Affiliates",
    color: "#EC4899",
    icon: Percent,
    order: 6,
  },
  totalPlatformFee: {
    header: "Platform Fees",
    color: "#F97316",
    icon: CreditCard,
    order: 7,
  },
  totalEstimatedFbmShippingCost: {
    header: "FBM Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 8,
  },
  totalEstimatedFbtFulfillmentFee: {
    header: "FBT Fulfillment Fee",
    color: "#3B82F6",
    icon: Truck,
    order: 9,
  },
  totalEstimatedFbtShippingCost: {
    header: "FBT Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 10,
  },
  totalEstimatedSellerShippingCost: {
    header: "Seller-Fulfilled Shipping Cost",
    color: "#3B82F6",
    icon: Truck,
    order: 11,
  },
};

export const ProductStatistics = () => {
  const navigate = useNavigate();
  const { isSidebarOpen, setIsSidebarOpen, currency, swapChartsOnDashboard } =
    useContext(UserStateContext);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [selectedDateRange, setSelectedDateRange] = useState("14");
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const {
    data: products,
    isLoading,
    error,
  } = useProductListBySales(fromDate, toDate, currentPage, pageSize);
  const {
    data: liteProducts,
    isLoading: isLiteLoading,
    error: liteError,
  } = useLiteProductStatistics(
    fromDate,
    toDate,
    products?.items.map((product) => product.productInfo.id) || []
  );

  const [selectedFields, setSelectedFields] = useState<
    (keyof LiteStatisticPeriod)[]
  >(["totalMerchandiseSales", "netProfits", "costOfGoods", "totalEstimatedAffiliateCommission", "units"]);

  useEffect(() => {
    const { from, to } = getDateRange(selectedDateRange);

    setFromDate(from);
    setToDate(to);
  }, [selectedDateRange]);

  const handleRowClick = (productId: string) => {
    navigate(`/analytics/products/${productId}`);
  };

  const getProductSalesData = (productId: string): LiteStatisticPeriod[] => {
    return liteProducts && liteProducts[productId]
      ? liteProducts[productId]
      : [];
  };

  const getAggregatedProductData = (
    productId: string,
    dataKey: keyof LiteStatisticPeriod
  ): number => {
    if (!liteProducts || !liteProducts[productId]) {
      return 0;
    }

    return liteProducts[productId].reduce((sum, period) => {
      const value = period[dataKey];
      return sum + (typeof value === "number" ? value : 0);
    }, 0);
  };

  const toggleField = (field: keyof LiteStatisticPeriod) => {
    setSelectedFields((prev) =>
      prev.includes(field) ? prev.filter((f) => f !== field) : [...prev, field]
    );
  };

  const sortedSelectedFields = selectedFields.sort((a, b) => fieldMappings[a].order - fieldMappings[b].order);

  const truncateProductName = (name: string, maxLength: number = 70) => {
    return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  };

  return (
    <div className="min-h-screen flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 bg-background overflow-y-auto min-h-screen">
        <header className="border-b pt-6 pb-2 shadow-sm h-auto flex flex-wrap items-center gap-4 px-4 md:px-6">
          <div className="flex items-center gap-4 w-full md:w-auto">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden text-foreground"
            >
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-foreground">
              Product Statistics
            </h2>
            <div className="flex items-center gap-4">
              <Select
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
              >
                <SelectTrigger className="w-[150px]">
                  <SelectValue placeholder="Select date range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="14">Last 14 days</SelectItem>
                  <SelectItem value="30">Last 30 days</SelectItem>
                  <SelectItem value="60">Last 60 days</SelectItem>
                  <SelectItem value="90">Last 90 days</SelectItem>
                  <SelectItem value="180">Last 180 days</SelectItem>
                  <SelectItem value="365">Last 365 days</SelectItem>
                </SelectContent>
              </Select>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="flex gap-2 items-center">
                    <FilterIcon className="w-4 h-4 mr-2" />
                    {selectedFields.length > 0 ? "" : "Select"} Fields (
                    {selectedFields.length})
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>Toggle Fields</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {Object.entries(fieldMappings)
                    .sort(([, a], [, b]) => (a as any).order - (b as any).order)
                    .map(([field, mapping]) => {
                      const typedMapping = mapping as { header: string, order: number };
                      return (
                        <DropdownMenuCheckboxItem
                          key={field}
                          onSelect={(e) => {
                            e.preventDefault()
                           }}
                          checked={selectedFields.includes(field as keyof LiteStatisticPeriod)}
                          onCheckedChange={() => toggleField(field as keyof LiteStatisticPeriod)}
                        >
                          {typedMapping.header}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex items-center space-x-2 w-full md:w-auto md:ml-auto">
            <ChartSettingsPopover />
            <CurrencySelect />
            <MyAccountDropdown />
          </div>
        </header>

        <div className="mt-6 px-6">
          <LoadingFallback loading={isLoading}>
            {error ? (
              <div className="text-red-500 font-semibold">
                Error: {error.message}
              </div>
            ) : (
              <>
                <div className="bg-background rounded-xl border overflow-hidden">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-muted/50">
                        <TableHead className="w-[250px] min-w-[250px] max-w-[250px] font-semibold text-foreground/70">
                          Name
                        </TableHead>
                        {sortedSelectedFields.map((field) => (
                          <TableHead
                            key={field}
                            className="font-semibold text-foreground/70"
                          >
                            {fieldMappings[field].header}
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {products &&
                      products.items &&
                      products.items.length > 0 ? (
                        products.items.map((product: any) => (
                          <TableRow
                            key={product.productInfo.id}
                            onClick={() =>
                              handleRowClick(product.productInfo.id)
                            }
                            className="hover:bg-muted/50 transition-colors cursor-pointer"
                          >
                            <TableCell className="py-4">
                              <div className="flex items-center space-x-4">
                                {product.productInfo.mainImageUrl ? (
                                  <img
                                    src={product.productInfo.mainImageUrl}
                                    alt={product.productInfo.title}
                                    className="w-16 h-16 object-cover rounded-lg shadow-sm"
                                  />
                                ) : (
                                  <div className="w-16 h-16 bg-muted rounded-lg shadow-sm flex items-center justify-center">
                                    <ImageIcon className="w-8 h-8 text-gray-400" />
                                  </div>
                                )}
                                <div>
                                  <h3 className="font-medium text-foreground">
                                    {truncateProductName(product.productInfo.title)}
                                  </h3>
                                  <p className="text-sm text-foreground/60">
                                    {product.productInfo.brandName}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            {sortedSelectedFields.map((field) => (
                              <TableCell key={field} className="px-1 py-2">
                                <ChartTileOverview
                                  tile={{
                                    value: fieldMappings[field].isNotCurrency ? getAggregatedProductData(
                                      product.productInfo.id,
                                      field
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }) : `${
                                      currency.symbol
                                    }${getAggregatedProductData(
                                      product.productInfo.id,
                                      field
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`,
                                    icon: fieldMappings[field].icon,
                                    color: `from-${fieldMappings[
                                      field
                                    ].color.slice(1)}-400 to-${fieldMappings[
                                      field
                                    ].color.slice(1)}-600`,
                                    chartColor: fieldMappings[field].color,
                                    format: "currency",
                                    dataKey: field,
                                    isNotCurrency: fieldMappings[field].isNotCurrency,
                                    dataLabel: "LIVE",
                                    gridClass: "col-span-2",
                                    heightClass: "h-[150px]",
                                  }}
                                  isLoading={isLiteLoading}
                                  isSettledLoading={false}
                                  combinedData={getProductSalesData(
                                    product.productInfo.id
                                  )}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={sortedSelectedFields.length + 1}
                            className="text-center py-8 text-foreground/60"
                          >
                            No products found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>

                {products && products.totalPages > 0 && (
                  <Pagination className="my-8 flex justify-center">
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                      {(() => {
                        const totalPages = products.totalPages;
                        const currentPage = products.currentPage;
                        let startPage = Math.max(currentPage - 1, 1);
                        let endPage = Math.min(startPage + 2, totalPages);
                        
                        if (endPage - startPage < 2) {
                          startPage = Math.max(endPage - 2, 1);
                        }

                        return Array.from(
                          { length: endPage - startPage + 1 },
                          (_, i) => startPage + i
                        ).map((page) => (
                          <PaginationItem key={page}>
                            <PaginationLink
                              onClick={() => setCurrentPage(page)}
                              isActive={page === currentPage}
                              className={`rounded-full cursor-pointer ${
                                page === currentPage
                                  ? "bg-blue-500/50"
                                  : "bg-background hover:bg-muted/50"
                              } border transition-colors`}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ));
                      })()}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                          className="cursor-pointer rounded-full bg-background border hover:bg-muted/50 transition-colors"
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                )}
              </>
            )}
          </LoadingFallback>
        </div>
      </main>
    </div>
  );
};
